// urls we don't want to be displayed as breadcrumbs on docs pages
const hiddenUrl = [
    '/en',
    '/charityKit',
    '/climateKit',
    '/payment',
    '/en/charityKit',
    '/en/climateKit',
    '/en/payment',
];

// new keys names for BreadCrumb component from PG-UI
const newBreadCrumbKeys = {
    pathname: 'url',
    crumbLabel: 'label',
};

export { hiddenUrl, newBreadCrumbKeys };
