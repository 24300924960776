import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Breadcrumb,
    Card,
    Grid,
    Link,
    MenuBoldIcon,
    Text,
    Title,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import {
    IsBigDesktop,
    getNumberFromMediaQuery,
    IsDesktop,
} from './../utils/Tools';
import { MenuContext, MenuContextItem, Layout } from '../components';
import { hiddenUrl, newBreadCrumbKeys } from '../utils/constants';
import { useScrollPosition } from '../utils/hooks/useScrollPosition';
import { useDebounce } from '../utils/hooks/useDebounce';
import { useWindowSize } from '../utils/hooks/useWindowSize';

const MenuBurgerContainer = styled.div`
    position: fixed;
    right: ${props => props.theme.space.lg};
    bottom: ${props => props.theme.space.lg};
    z-index: ${props => props.theme.zindex.front};
`;

const MainDocContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
`;

const MdxFilesContainer = styled.div`
    max-width: ${getNumberFromMediaQuery(ThemeDefault.query.min.md)}px;
`;

export default ({ data, pageContext }) => {
    const { t } = useTranslation('pages');
    const { body, frontmatter, tableOfContents } = data.mdx;
    const [contextMenuOpened, setContextMenuOpened] = useState(false);
    const [activeLink, setActiveLink] = useState();
    const [sections, setSections] = useState([]);
    const [scrollTop, setScrollTop] = useState(0);
    const windowSize = useDebounce(useWindowSize(), 200);

    const {
        breadcrumb: { crumbs },
    } = pageContext;

    const toggleContextMenu = () => {
        setContextMenuOpened(!contextMenuOpened);
    };

    useEffect(() => {}, [contextMenuOpened]);

    useScrollPosition(
        ({ currPos }) => {
            setScrollTop(currPos.y + 120);
        },
        [],
        null,
        true,
        200,
    );

    useEffect(() => {
        let linksTmp = [];
        let sectionsTmp = [];

        // we extract from graphQl data all the h2 and h3 titles links in the page
        tableOfContents &&
            tableOfContents.items.forEach(child => {
                if (!child) {
                    return null;
                }
                const id = child.url.replace('#', '');
                linksTmp.push(id);

                if (child.items) {
                    child.items.forEach(item => {
                        const id = item.url.replace('#', '');
                        linksTmp.push(id);
                    });
                }
            });

        // we build an array with the corresponding ids links and y to top positions
        linksTmp.forEach(link => {
            const target = document.getElementById(link);
            if (target) {
                sectionsTmp.push({
                    id: link,
                    top: target.offsetTop,
                });
            }
        });
        setSections(sectionsTmp);
    }, [windowSize, tableOfContents]);

    // finally we detect each active link based on y to top position
    useEffect(() => {
        sections.forEach((section, index) => {
            if (
                index < sections.length - 1 &&
                scrollTop >= section.top &&
                scrollTop <= sections[index + 1].top
            ) {
                setActiveLink(section.id);
            } else if (
                index === sections.length - 1 &&
                scrollTop >= section.top
            ) {
                setActiveLink(section.id);
            }
        });
    }, [windowSize, scrollTop, sections]);

    // we filter, rename keys and translate labels from crumbs data received to fit BreadCrumb PG-UI component expectations and languages
    const renameObjectKeys = (newKeys, data) => {
        return data
            .filter(obj => !hiddenUrl.includes(obj.pathname))
            .map(obj => {
                const renamedKeysObject = _.mapKeys(obj, (value, key) => {
                    return newKeys[key];
                });
                return _.mapValues(renamedKeysObject, (value, key) => {
                    return key === 'label' ? t(`breadcrumb.${value}`) : value;
                });
            });
    };

    return (
        <Layout hasBanner={false}>
            <Box marginTop={IsDesktop() ? 'none' : 'xl'}>
                <Breadcrumb
                    elements={
                        crumbs && renameObjectKeys(newBreadCrumbKeys, crumbs)
                    }
                    marginLateral={IsDesktop ? 'md' : 'sm'}
                    marginTop="xl"
                    marginBottom="md"
                />
            </Box>

            <Grid alignItems="center" justifyContent="flex-start">
                <Card hasBackground={false} blockWidth="none">
                    <Title
                        htmlTag="h1"
                        textSize="xl"
                        marginTop="sm"
                        hasUnderline={true}
                        colorPallet="wab"
                        colorWab="grey50"
                    >
                        {frontmatter.title}
                    </Title>

                    <Text colorPallet="wab" colorWab="grey50" textSize="md">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed non risus. Suspendisse lectus tortor, dignissim sit
                        amet, adipiscing nec, ultricies sed.
                    </Text>
                </Card>
            </Grid>

            <Grid alignItems="center" justifyContent="flex-start">
                <MainDocContainer>
                    <MdxFilesContainer>
                        {data.mdx ? (
                            <MDXRenderer>{body}</MDXRenderer>
                        ) : (
                            <div>{t('docError')}</div>
                        )}
                    </MdxFilesContainer>

                    {tableOfContents.items && (
                        <MenuContext
                            isOpen={contextMenuOpened}
                            toggleMenu={toggleContextMenu}
                        >
                            {tableOfContents.items.map((item, index) => (
                                <MenuContextItem
                                    key={index}
                                    activeLink={activeLink}
                                    toggleMenu={
                                        IsBigDesktop()
                                            ? null
                                            : toggleContextMenu
                                    }
                                >
                                    <a href={item.url}>
                                        <Link
                                            colorPallet="wab"
                                            colorWab="grey50"
                                            hasUnderline={false}
                                        >
                                            {item.title}
                                        </Link>
                                    </a>

                                    {item.items && (
                                        <ul className="menu">
                                            {item.items.map((i, index) => (
                                                <MenuContextItem
                                                    key={index}
                                                    hasDot={true}
                                                    activeLink={activeLink}
                                                    textSize="xs"
                                                    toggleMenu={
                                                        IsBigDesktop()
                                                            ? null
                                                            : toggleContextMenu
                                                    }
                                                >
                                                    <a href={i.url}>
                                                        <Link
                                                            colorPallet="wab"
                                                            colorWab="grey40"
                                                            hasUnderline={false}
                                                        >
                                                            {i.title}
                                                        </Link>
                                                    </a>
                                                </MenuContextItem>
                                            ))}
                                        </ul>
                                    )}
                                </MenuContextItem>
                            ))}
                        </MenuContext>
                    )}

                    <MenuBurgerContainer className="hideOnBigScreen">
                        <MenuBoldIcon
                            hasBackground={true}
                            hasHover={true}
                            htmlTag="button"
                            iconSize="lg"
                            isActive={true}
                            onClick={toggleContextMenu}
                        />
                    </MenuBurgerContainer>
                </MainDocContainer>
            </Grid>
        </Layout>
    );
};

export const query = graphql`
    query DocBySlug($locale: String!, $slug: String!) {
        mdx(
            fields: { locale: { eq: $locale } }
            frontmatter: { slug: { eq: $slug } }
        ) {
            frontmatter {
                title
                slug
                language
            }
            body
            excerpt
            tableOfContents
        }
    }
`;
