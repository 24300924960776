import { useRef, useLayoutEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

/**
 * used in useScrollPosition to return x and y values
 */
const getScrollPosition = ({ element, useWindow }) => {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top };
};

/**
 * @see {@link https://github.com/n8tb1t/use-scroll-position}
 * @see {@link https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj}
 * @param {func} effect - effect callback
 * @param {array} deps - for effects to fire on selected dependencies change
 * @param {ElementRef} element - get scroll position for a specified element by reference
 * @param {bool} useWindow - use window.scroll instead of document.body.getBoundingClientRect() to detect scroll position
 * @param {number} wait - the timeout in ms. Good for performance.
 */
export const useScrollPosition = (effect, deps, element, useWindow, wait) => {
    const position = useRef(getScrollPosition({ useWindow }));
    let throttleTimeout = useRef(null);

    useLayoutEffect(() => {
        const callBack = () => {
            const currPos = getScrollPosition({ element, useWindow });
            effect({ prevPos: position.current, currPos });
            position.current = currPos;
            throttleTimeout.current = null;
        };

        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout.current === null) {
                    throttleTimeout.current = setTimeout(callBack, wait);
                }
            } else {
                callBack();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [deps, wait, effect, element, useWindow]);
};
